export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Carolyn Perkins',
        email: 'carolyn.p@elstar.com',
        authority: ['admin', 'user','teacher'],
        password: '123Qwe',
        accountUserName: 'admin'
    },
    {
        id: '22',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Institution admin',
        email: 'carolyn.p@elstar.com',
        authority: ['user'],
        password: 'inst@123',
        accountUserName: 'institution_admin'
    },
    {
        id: '23',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Teacher admin',
        email: 'carolyn.p@elstar.com',
        authority: ['teacher'],
        password: 'teacher@123456',
        accountUserName: 'alex_pedia'
    },
    {
        id: '24',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Kunal',
        email: 'kunal4sd@gmail.com',
        authority: ['admin', 'user','teacher'],
        password: 'demo1234',
        accountUserName: 'admin'
    },


   
]