import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import access from './accessSlice'

const reducer = combineReducers({
    session,
    user,
    access
})

export default reducer