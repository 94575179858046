const appConfig = {
  apiPrefix: "/api",
  // authenticatedEntryPath:
    // window.location.pathname !== "/" ? window.location.pathname : "dashboard",
  //authenticatedEntryPath: '/home',
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  enableMock: false,
};

export default appConfig;
