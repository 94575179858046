import { theme } from 'twin.macro'

const twColor = theme`colors`

export const COLOR_1 = twColor.indigo['600'] 
export const COLOR_2 = twColor.blue['500']
export const COLOR_3 = twColor.emerald['500']
export const COLOR_4 = twColor.amber['500']
export const COLOR_5 = twColor.red['500']
export const COLOR_6 = twColor.purple['500']
export const COLOR_7 = twColor.cyan['500']

export const COLOR_1_LIGHT = twColor.indigo['100'] 
export const COLOR_2_LIGHT = twColor.blue['100']
export const COLOR_3_LIGHT = twColor.emerald['100']
export const COLOR_4_LIGHT = twColor.amber['100']
export const COLOR_5_LIGHT = twColor.red['100']
export const COLOR_6_LIGHT = twColor.purple['100']
export const COLOR_7_LIGHT = twColor.cyan['100']


export const COLORS = [
	COLOR_1,
	COLOR_2,
	COLOR_3,
	COLOR_4,
	COLOR_5,
	COLOR_6,
	COLOR_7
]
export const COLOR_1GRAPH = '#124786'
export const COLOR_2GRAPH = '#28afea' 

export const COLOR_1GRAPH2 = '#ffa800'
export const COLOR_2GRAPH2 = '#00c3b8' 
export const COLOR_3GRAPH2 = '#3b82f6' 
export const COLOR_4GRAPH2 = '#BFD5C9' 
export const COLOR_5GRAPH2 = '#8b5cf6' 
export const COLOR_6GRAPH2 = '#3C6997' 
export const COLOR_7GRAPH2 = '#808000' 
export const COLOR_8GRAPH2 = '#d946ef' 
export const COLOR_9GRAPH2 = '#F582A8' 
export const COLOR_10GRAPH2 = '#ef4444' 
export const COLOR_11GRAPH2 = '#745D20' 
export const COLOR_12GRAPH2 = '#CEA660' 
export const COLOR_13GRAPH2 = '#003f5c' 

export const COLOR_1GRAPH3= '#124786'
export const COLOR_2GRAPH3 = '#fde366' 

export const COLOR_1GRAPH4= '#ffa92d'
export const COLOR_2GRAPH4 = '#00c8c0' 

export const COLOR_1GRAPH5= '#55c6e6'
export const COLOR_2GRAPH5 = '#3690cc' 
export const COLOR_3GRAPH5 = '#115e9b' 

export const COLOR_1GRAPH6 = '#124786'

export const COLOR_1GRAPH7 = '#FFA92D'

export const compreColor = [
	
	COLOR_1GRAPH,
	COLOR_2GRAPH,
	
]

export const gradeWisecolor = [	
	COLOR_1GRAPH2,
	COLOR_2GRAPH2,
	COLOR_3GRAPH2,
	COLOR_4GRAPH2
]
export const gradeWisecolorNew = [	
	COLOR_1GRAPH2,
	COLOR_2GRAPH2,
	COLOR_3GRAPH2,
	COLOR_4GRAPH2,
	COLOR_5GRAPH2,
	COLOR_6GRAPH2,
	COLOR_7GRAPH2,
	COLOR_8GRAPH2,
	COLOR_9GRAPH2,
	COLOR_10GRAPH2,
	COLOR_11GRAPH2,
	COLOR_12GRAPH2,
	COLOR_13GRAPH2,
]
export const durationColor = [
	
	COLOR_1GRAPH3,
	COLOR_2GRAPH3,
	
]
export const userBookcolor = [
	
	COLOR_1GRAPH4,
	COLOR_2GRAPH4,
	
]
export const accuracyColor = [
	
	COLOR_1GRAPH5,
	COLOR_2GRAPH5,
	COLOR_3GRAPH5,
	
]
export const bookReadcolor = [
	
	COLOR_1GRAPH6,
	
	
]
export const bookReadcolorNew = [
	
	COLOR_1GRAPH7,
	COLOR_3_LIGHT
	
]
export const COLORS_LIGHT = [
	COLOR_1_LIGHT,
	COLOR_2_LIGHT,
	COLOR_3_LIGHT,
	COLOR_4_LIGHT,
	COLOR_5_LIGHT,
	COLOR_6_LIGHT,
	COLOR_7_LIGHT
]