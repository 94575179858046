import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
      
        return new Promise((resolve, reject) => {
           // console.log('param>>',param?.url)
          
            BaseService(param).then(response => {
             
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export default ApiService