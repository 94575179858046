import { createSlice } from '@reduxjs/toolkit'

export const accessSlice = createSlice({
	name: 'auth/access',
	initialState: {
        access: '',
        accessinfo:"",
    },
	reducers: {
        setAccess: (state, action) =>  {
            state.access = action.payload
        },
        setAaccessinfo: (state, action) =>  {
            // console.log("state.accessinfo",action.payload)
            state.accessinfo = action.payload
        }
	},
})

export const {setAccess,setAaccessinfo } = accessSlice.actions

export default accessSlice.reducer