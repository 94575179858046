import axios from "axios";
import appConfig from "configs/app.config";
import {
  TOKEN_TYPE,
  REQUEST_HEADER_AUTH_KEY,
  REQUEST_HEADER_TOKEN_KEY_NAME,
  REQUEST_HEADER_FINGERPRINT_KEY_NAME,
} from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
// import axiosRetry from "axios-retry";

const unauthorizedCode = [401];

let baseURLis =
  window.location.href.includes("http://localhost:3000/") ||
  window.location.href.includes("https://dev-v2.readabilitytutor.com/")||
  window.location.href.includes("https://readabilitytutor.cleprtech.com/")
    ? "https://predev-api.readabilitytutor.com/API/v1"
    : "https://bb-api.readabilitytutor.com/API/v1";
const BaseService = axios.create({
  timeout: 300000,
  baseURL: baseURLis, //appConfig.apiPrefix,
});
// axiosRetry(BaseService, { retries: 3 });
BaseService.interceptors.request.use(
  (config) => {
    // if(config.url=='/Login' || config.url =='/sign-up' ||  config.url =='/ForgotPassword' || config.url =='/Profile/Save' || config.url =='/ChangePassword'  || config.url =='/reset-password' ){

    //     config.baseURL="https://predev-api.readabilitytutor.com/API/v1";
    //    // console.log('baseURL if>>>>',config.baseURL);
    // }
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);

    const accessToken = persistData.auth.session.token;
    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
      config.headers[REQUEST_HEADER_TOKEN_KEY_NAME] = `${accessToken}`;
    }
    config.headers[REQUEST_HEADER_FINGERPRINT_KEY_NAME] = "readability";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const requestConfig = {
  timeout: 60000,
  baseURL: "https://predev-api.readabilitytutor.com/API/v1",
};

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    // if()
    // const {request}=response
    // const {responseURL}=request
    // console.log(window.location.href.includes("http://localhost:3000/"))
    // console.log(window.location.href)
    // console.log(window.location.href.includes("https://dev-v2.readabilitytutor.com/"))
    // console.log(window.location.href==window.location.href.includes("http://localhost:3000/"))
    // if(response &&responseURL=="https://predev-api.readabilitytutor.com/API/v1/Profile/Access"||responseURL=="https://predev-api.readabilitytutor.com/API/v1/Profile/Me"){
    if (response && response.data?.ResultCode == "TokenExpired") {
      const completeUrl = BaseService.getUri();
      // console.log("Complete URL:", completeUrl);
      // console.log({BaseService})
      store.dispatch(onSignOutSuccess());
    }
    // if (error.response && error.response.status === 401) {
    //   // Handle 401 error (Unauthorized)
    //   console.log('Unauthorized: Redirecting to login page...',error);
    //   // Redirect the user to the login page or display a message
    // }
    if (axios.isCancel(error)) {
      return {
        data: {
          Data: {
            // "PageIndex": 1,
            // "PageSize": 10,
            // "TotalCount": 0,
            Data: [],
          },
        },
      };
      // console.log('Request cancelled:', error.message);
      // Handle cancellation gracefully
    }
    // else {
    //   return { data:{
    //     "Data": {
    //         // "PageIndex": 1,
    //         // "PageSize": 10,
    //         // "TotalCount": 0,
    //         "Data": []
    //     }} }
    //   console.error('An error occurred:', error);
    //   // Handle other errors
    // }
    // }
    return Promise.reject(error);
  }
);

export default BaseService;
