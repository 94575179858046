import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  apiSignIn,
  apiSignOut,
  apiSignUp,
  apiGetProfile,
} from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { useEffect } from "react";
import BaseService from "services/BaseService";
import { setAaccessinfo, setAccess } from "store/auth/accessSlice";
import { setInstitutesData, setTeacherData } from "store/readability/readabilitySlice";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { token, signedIn } = useSelector((state) => state?.auth?.session);
  // useEffect(() => {
  //   if (token) {
  //     apiGetProfile()
  //     .then((profileRespone) => {
  //       Accessinfo(token)
  //       getData(token)
  //         const {
  //           CountryId,
  //           CountryName,
  //           DisplayName,
  //           UserName,
  //           Email,
  //           MobileNo,
  //           RoleId,
  //           RoleName,
  //           UserTypeName,
  //           IconImageRelativePhysicalPath
  //         } = profileRespone.data.Data;
  //         const userData = {
  //           CountryId: CountryId,
  //           CountryName: CountryName,
  //           DisplayName: DisplayName,
  //           userName: UserName,
  //           email: Email,
  //           MobileNo: MobileNo,
  //           RoleId: RoleId,
  //           RoleName: RoleName,
  //           UserTypeName: UserTypeName,
  //           authority: ["user", "admin", "teacher"],
  //           avatar: IconImageRelativePhysicalPath,
  //         };

  //         dispatch(setUser(userData));

  //         const redirectUrl = query?.get(REDIRECT_URL_KEY);
  //       })
  //       .catch((error) => {
  //         //console.log(error, " error >>> ")
  //       });
   
  //   }
  // }, [token]);
  const getData = (obj) => {
    // setLoading(true);
    BaseService.get(
      "/Profile/Information",
      {  },
      {
        headers: {
          // Your headers
          "Content-Type": "application/json", // For example, setting the content type to JSON
          Authorization: "Bearer" + obj, // Include any authorization token if needed
        },
      }
    ).then((response) => {
        dispatch(setAccess(response?.data?.Data));
      });
    };
    const Accessinfo= async(UserToken)=>{
      // alert(UserToken)
      if(UserToken){
        
        const resp= await BaseService.get(
          "/Profile/Access",
          {  },
          {
            headers: {
              // Your headers
              "Content-Type": "application/json", // For example, setting the content type to JSON
              Authorization: "Bearer" + UserToken, // Include any authorization token if needed
            },
          }
          ).then((response) => {            
            dispatch(setAaccessinfo(response?.data?.Data));
       });
    }
  }

  const signIn = async (values) => {
    try {
      values.FingerPrintKey = "readability";
      const resp = await apiSignIn(values);
      const { UserToken } = resp?.data.Data;
        if(UserToken){
          Accessinfo(UserToken)
          getData(UserToken)
          dispatch(onSignInSuccess(resp?.data?.Data?.UserToken));
          setTimeout(() => { 
            navigate('/dashboard')
              // window.location.href = '/dashboard';
            });
        }else{
            return {
                status: "failed",
                message: resp?.data?.message,
              };
        }
        
        return {
          status: "success",
          message: "",
        };
      // }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.ResultDescription || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      // console.log({resp})
      if (resp?.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        // if(resp.data.user) {

        const useData = {
          avatar: "",
          userName: "Anonymous",
          authority: ["USER"],
          email: "",
        };
        // resp.data.user = useData

        dispatch(
          setUser(
            useData || {
              avatar: "",
              userName: "Kunal",
              authority: ["USER"],
              email: "kunal4sd@gmail.com",
            }
          )
        );
        // }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(setAccess(""))
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    //await apiSignOut()
    dispatch(setTeacherData());
    dispatch(setInstitutesData())
    handleSignOut();

  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
